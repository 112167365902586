import { cz, explorer, guest, node, stream} from '@/plugins/axios'

import Vue from 'vue'
import { includes } from 'lodash'
import router from '@/router'
import store from '@/store'
import { secondsDifference, minutesDifference } from '@/utils/date.utils.ts'

export const getTokenFromStorage = () => {
  try {
    return window.localStorage.getItem('ctkn')
  } catch {
    Vue.prototype.$notify('Unable to access your browser storage')
  }
}

export const saveTokenToStorage = token => {
  try {
    return window.localStorage.setItem('ctkn', token)
  } catch {
    Vue.prototype.$notify('Unable to access your browser storage')
  }
}

export const removeTokenFromStorage = () => {
  try {
    return window.localStorage.removeItem('ctkn')
  } catch {
    Vue.prototype.$notify('Unable to access your browser storage')
  }
}

export const setAxiosAuth = token => {
  const instances = [node, explorer, stream, cz]
  instances.forEach(instance => {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  })
}

export const clearAxiosAuth = () => {
  const instances = [node, explorer, stream, cz]
  instances.forEach(instance => {
    delete instance.defaults.headers.common['Authorization']
  })
}

export const isExpired = authState => {
  const timeNow = new Date()
  const expiryTime = new Date(authState.jwt?.exp * 1000)
  return secondsDifference(expiryTime, timeNow) <= 0
}

export const isRefreshable = authState => {
  const timeNow = new Date()
  const creationTime = new Date(authState.jwt?.iat * 1000)
  return minutesDifference(timeNow, creationTime) >= 15
}

export const checkSession = async (_to, _from, next) => {
  const { auth } = store.state

  if (isExpired(auth)) {
    return logOut()
  }

  if (isRefreshable(auth)) {
    await store.dispatch('auth/refresh')
  }

  next()
}

export const hasToken = async (to, from, next) => {
  const { token } = to.query

  if (!token) return next('/')

  try {
    const { data } = await guest.get(`auth/verify?token=${token}`)
    if (data.userId) return next()
  } catch {
    return next('/')
  }
}

export const logOut = () => {
  store.dispatch('auth/logOut')
  Vue.prototype.$modal.close()
  Vue.prototype.$notify('You have been logged out')
  router.push({ name: 'auth-login' }).catch(() => {})
}

export const checkStatic = (route, next) => {
  const allowedRoutes = ['companion', 'static-search', 'autocheck']
  const forbiddenRoute = !includes(allowedRoutes, route)
  const isStatic = store.getters['auth/isIntegration']

  if (isStatic && forbiddenRoute) return logOut()

  return next()
}

export const checkTerms = () => {
  const isUser = store.getters['auth/isUser']
  const isVerified = store.getters['auth/isVerified']
  if (isUser && !isVerified)
    Vue.prototype.$modal.open('account/ContractGeneral')
}
