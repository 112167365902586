<template>
  <div class="app">
    <header class="header">
      <nav class="navbar">
        <div class="container">
          <div class="navbar-brand is-flex is-align-items-center">
            <div
              to="/"
              class="navbar-logo"
            >
              <img
                :src="require(`core/img/logos/${[logo]}-logo.png`)"
                alt="Percayso Trade"
                aria-haspopup="true"
                aria-controls="brand-menu"
                class="animated flipInX logo-width"
              >
            </div>
          </div>
        </div>
      </nav>
    </header>
    <main class="main">
      <router-view />
    </main>
    <footer class="footer">
      <span class="icon is-small is-valigned">
        <i class="fal fa-info-circle" />
      </span>
      <span
        class="is-valigned"
      >You are using our static integration of Companion</span>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'StaticLayout',
  computed: {
    logo() {
      return process.env.VUE_APP_TYPE
    },
  },
}
</script>

<style lang="sass" scoped>
.navbar
  background-color: #013366
  .navbar-logo
    padding: 0.5rem 0.75rem
  .logo-width
    width: $logo-width
    height: auto
  .navbar-item
    color: #ffffff
</style>
