import { node } from '@/plugins/axios'
import { percent } from '@/services/MathsService'

export * from 'modules/autocheck-batch/services/autocheck-batch.service'
export * from './additional-data/additionalData'

export const getOne = async uuid => {
  try {
    const { data } = await node.get(`/autocheck/${uuid}`)
    return data.autocheck[0]
  } catch (err) {
    throw err
  }
}

export const create = async ({ request, email }) => {
  try {
    const { data } = await node.post('/autocheck', request)
    const uuid = data?.autocheck?.response?.uuid

    const response = JSON.parse(data?.autocheck?.response?.response)
    const capToken = response?.valuationData?.token

    if (capToken) sendCapEmail({ capToken, email })

    return { uuid }
  } catch (err) {
    throw err
  }
}

export const precheck = async ({ value, type, mileage }) => {
  const params = {
    [type]: value
  }

  if (mileage) {
    params.mileage = mileage
  }

  try {
    const { data } = await node.get('/autocheck/precheck', { params })
    return data
  } catch (err) {
    throw err
  }
}

export const getStatus = data => {
  const parsedData = typeof data === 'object' ? data : JSON.parse(data)

  const extra = getExtraData(parsedData)
  const extra2 = extra?.some(({ status }) => status === 2)
  const extra1 = extra?.some(({ status }) => status === 1)

  const warnings = parsedData?.messageDetails?.messageList1
  const voidVrm = warnings.some(message => message.id === '85')
  const type96 = warnings.some(message => message.id === '15')

  const condition = parsedData?.conditionData?.conditionDetailsItems ?? []
  const damage = getCondition(condition).count
  const stolen =
    parsedData?.stolenVehicleDetails?.status ||
    condition.filter(item => item.theftIndicatorLiteral === 'STOLEN').length

  const exported = parsedData?.vehicleRegistration?.exported
  const risk = parsedData?.highRiskRecordDetails?.highRiskCount
  const plate = parsedData?.plateChangeDetails?.plateChangeCount
  const imported = parsedData?.vehicleRegistration?.imported
  const finance = parsedData?.finance?.financeDataRecordCount
  const scrapped = parsedData?.vehicleRegistration?.scrapped
  const danger =
    stolen || risk || exported || scrapped || damage || type96 || voidVrm

  if (extra2 || danger) {
    return { text: 'Warning', class: 'danger' }
  } else if (extra1 || plate || finance || imported) {
    return { text: 'Caution', class: 'warning' }
  } else {
    return { text: 'Clear', class: 'success' }
  }
}

export const mileageAnomaly = (mileage, index, items, userInput) => {
  return items.length
    ? mileage <
        items[index === items.length - 1 ? items.length - 1 : index + 1]
          .mileage -
          (userInput ? 1000 : null)
    : false
}

// remove mileage anomaly message if user didn't request mileage data
const filterMileageMessages = warnings => {
  return warnings?.filter(({ id }) => !['18', '19'].includes(id))
}

export const getGrid = response => {
  const extra = getExtraData(response)

  const plateChanges = response?.plateChangeDetails?.plateChangeCount
  const plateOrange = plateChanges ? 1 : null

  const warnings = response?.messageDetails.messageList1
  const type96 = warnings?.some(({ id }) => id === '15')
  const voidVrm = warnings?.some(({ id }) => id === '85')
  const plateRed = type96 || voidVrm ? 2 : null

  const condition = response?.conditionData?.conditionDetailsItems ?? []

  const conditionStolen = condition.some(
    ({ status }) => status === 'VEHICLE HAS BEEN STOLEN'
  )
  const altStolen = response?.stolenVehicleDetails?.status
  const stolen = !!(conditionStolen || altStolen)

  const damage = getCondition(condition).count

  return {
    stolen: stolen,
    extra,
    export: response?.vehicleRegistration?.exported,
    risk: response?.highRiskRecordDetails?.highRiskCount,
    plate: plateRed || plateOrange,
    import: response?.vehicleRegistration?.imported,
    finance: response?.finance?.financeDataRecordCount,
    scrapped: response?.vehicleRegistration?.scrapped,
    damage
  }
}

export const getPercentOfOcn = response => {
  const cazana = response?.cazana
  if (!cazana) return null

  const { onTheRoadPrice, retail } = cazana
  if (!onTheRoadPrice) return null

  return percent(retail.pooled, onTheRoadPrice)
}

export const getExtraData = data => {
  const vic = data?.vehicleIDCheckData.vicCount
  const warnings = data?.messageDetails.messageList1
  const noMot = data?.dvla?.motStatus === 'Not valid'

  const items = []

  if (vic) items.push({ status: 2, section: 'vic' })
  if (noMot) items.push({ status: 1, section: 'mot' })
  // remove mileage anomaly message if user did not request data
  if (warnings?.length) {
    if (data?.mileageDetails) items.push(...loopWarnings(warnings))
    else {
      const list = filterMileageMessages(warnings)
      items.push(...loopWarnings(list))
    }
  }

  return items.length ? sortExtraData(items) : null
}

const sortExtraData = items => items.sort((a, b) => b.status - a.status)

const loopWarnings = warnings => {
  const items = []
  for (let message of warnings) {
    const data = getExtraDataSection(message.id)
    if (data) items.push(data)
  }
  return items
}

// default to 0 because other messages already accounted for
export const getExtraDataSection = id => {
  switch (id) {
    // high risk record
    case '12':
      return { status: 2, section: 'risk' }
    // mileage order
    case '18':
    case '19':
      return { status: 1, section: 'mileage' }
    // colour change
    case '25':
      return { status: 1, section: 'colours' }
    // VIN doesn't match or VOID VRM
    case '29':
      return { status: 2, section: 'plate' }
    default:
      return null
  }
}

export const buildDescription = response => {
  const values = []

  const props = [
    ['vehicleRegistration', 'yearOfManufacture'],
    ['vehicleRegistration', 'makeModel'],
    ['additionalSmmtData', 'nominalEngineCapacity'],
    ['performanceAndConsumptionData', 'powerBhp']
  ]

  props.forEach(prop => {
    const key = prop[1]
    const value = response?.[prop[0]]?.[prop[1]]
    if (value) {
      if (key === 'powerBhp') {
        values.push(`${parseInt(value)}BHP`)
      } else if (key === 'nominalEngineCapacity') {
        values.push(parseFloat(value).toFixed(1))
      } else {
        values.push(value)
      }
    }
  })

  return values.join(' ')
}

export const buildPreDescription = response => {
  const values = []
  const props = ['year', 'makeModel', 'engineCapacity', 'bhp']

  props.forEach(prop => {
    const value = response.prop
    if (value) {
      if (prop === 'bhp') {
        values.push(`${parseInt(value)}BHP`)
      } else if (prop === 'engineCapacity') {
        values.push(parseFloat(value).toFixed(1))
      } else {
        values.push(value)
      }
    }
  })

  return values.join(' ')
}

export const getPreviousChecks = async vin => {
  const params = { vin }
  try {
    const { data } = await node.get('/autocheck/previous', { params })
    return data
  } catch (err) {
    throw err
  }
}

export const sendCapEmail = async params => {
  try {
    await node.post('/autocheck/cap', params)
  } catch (err) {
    throw err
  }
}

export const getCondition = items => {
  const list = items.filter(
    item =>
      item.status !== 'EXPORTED' &&
      item.status !== 'IMPORTED' &&
      item.status !== 'VEHICLE HAS BEEN STOLEN'
  )
  const count = list.length
  return { list, count }
}

export const getBatch = async uuid => {
  const params = { uuid }
  try {
    const { data } = await node.get('autocheck/batch', { params })
    return data
  } catch (err) {
    throw err
  }
}

export const motorcycle = doorPlanLiteral => {
  const doorPlanLiterals = [
    'goods tricycle',
    'moped',
    'scooter',
    'scooter combination',
    'motorcycle',
    'm/c combination',
    'moped'
  ]
  return doorPlanLiterals.includes(doorPlanLiteral.toLowerCase())
}

export const getSpec = response => {
  const {
    vehicleRegistration: main,
    additionalSmmtData: smmt,
    engineAndTechnicalData: engine,
    performanceAndConsumptionData: performance
  } = response
  const isMotorcycle = motorcycle(main?.doorPlanLiteral)
  return {
    make: main.make || smmt.marque,
    model: isMotorcycle ? main.model : smmt.range || main.model,
    trim: isMotorcycle ? null : smmt.modelVariant,
    year: main.yearOfManufacture,
    firstReg: main.dateFirstRegistered || main.ukDateFirstRegistered,
    bodyStyle: main.doorPlanLiteral || smmt.bodyStyle,
    transmission: smmt.transmission || main.transmission,
    doors: isMotorcycle ? null : smmt.numberOfDoors,
    gears: main.gears || smmt.numberOfGears,
    engineNumber: main.engineNumber,
    fuelType: main.fuel || smmt.fuelType,
    engineCapacity: main.engineCapacity || smmt.engineCapacity,
    colour: main.colour,
    cylinders: isMotorcycle ? null : engine.numberOfCylinders,
    powerOutputBhp: performance.powerBhp,
    powerOutputKw: performance.powerKw
  }
}

export const createStaticAutoCheck = async ({ vrm, mileage, token }) => {
  try {
    const precheckData = await precheck({ 
      value: vrm, 
      type: 'vrm',
      mileage 
    })

    // Create autocheck request
    const request = {
      vrm,
      vin: precheckData.vin,
      mileageEstimated: false,
      dgLevel: null,
      dgTerm: null,
      valuationCazana: true,
      valuationCap: false,
      valuationGlass: false,
      mileage: mileage
    }

    const { data } = await node.post('/autocheck', request)
    const uuid = data?.autocheck?.response?.uuid

    const response = JSON.parse(data?.autocheck?.response?.response)
    const capToken = response?.valuationData?.token

    // Only try to send CAP email if we have both a token and email
    if (capToken && token) {
      await sendCapEmail({ capToken, email: token })
    }

    return { uuid }
  } catch (err) {
    throw err
  }
} 