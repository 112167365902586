import store from '@/store'

export const staticPermissionGuard = async (to, from, next) => {
  const token = to.query.token
  if (!token) {
    store.dispatch('auth/logOut')
    return next({ name: 'auth-login' })
  }

  try {
    await store.dispatch('auth/logOut')
    
    await store.dispatch('auth/staticLogIn', token)
    
    let permission
    switch(to.params.service) {
      case 'autocheck':
        permission = 'hasAutoCheck'
        break
      case 'motorcheck':
        permission = 'hasMotorCheck'
        break
      case 'companion':
        permission = 'isCompanionUser'
        break
    }
    
    const hasPermission = store.getters[`auth/${permission}`]
    
    if (!hasPermission) {
      await store.dispatch('auth/logOut')
      return next({ name: 'auth-login' })
    }

    return next()
  } catch (err) {
    await store.dispatch('auth/logOut')
    return next({ name: 'auth-login' })
  }
}
