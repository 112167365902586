import Vue from 'vue'
import { node } from '@/plugins/axios'

const notify = params => Vue.prototype.$notify(params)

export const openPriceChangeModal = async listing => {
  try {
    const { data } = await node.get(`stockview/listings/${listing.listingId}/price-changes`, {
      sellerId: listing.sellerId,
      listingId: listing.listingId
    })
    Vue.prototype.$modal.open('stockview/StockviewPriceChange', {
      priceChanges: data.items,
      listing: listing
    })
    Vue.prototype.$mxp.track('stockview_price_change')
  } catch {
    notify('Price changes unavailable')
  }
}

export const truncate = (value, maxLength) => {
  const end = value.length === maxLength - 1 ? maxLength - 1 : maxLength - 2
  return `${value.slice(0, end).trim()}...`
}

export const getDescription = ({ listing, maxLength }) => {
  const { make, model, trim } = listing
  if (!make || !model) return null

  const full = `${make} ${model} ${trim}`
  const truncated = full.length >= maxLength
  if (truncated) return { full, truncated: truncate(full, maxLength) }
  else return { full, truncated }
}

const pricePositionTooltip = ({ pricePosition }) => {
  switch (pricePosition) {
    case 'PricedWell':
      return 'Price appropriate for time on market'
    case 'ConsiderReview':
      return 'Consider price review soon'
    case 'OverPriced':
      return 'Overpriced for time on market'
    case 'UnderPriced':
      return 'Underpriced for time on market'
    default:
      return null
  }
}

const isPricedWell = ({ pricePosition }) => {
  return pricePosition === 'PricedWell'
}

const priceChange = ({ daysSinceLastPriceChange, daysInStock }) => {
  const hasData = daysSinceLastPriceChange || daysSinceLastPriceChange === 0
  return hasData && daysSinceLastPriceChange !== daysInStock
}

const priceChangeText = ({ daysSinceLastPriceChange: days }) => {
  return !days ? 'Today' : days === 1 ? 'Yesterday' : `${days}d ago`
}

export const getPriceInfo = listing => {
  return {
    pricePositionTooltip: pricePositionTooltip(listing),
    pricedWell: isPricedWell(listing),
    priceChange: priceChange(listing),
    priceChangeText: priceChangeText(listing)
  }
}

const icons = {
  success: 'has-text-success far fa-check',
  warning: 'has-text-warning far fa-info-circle',
  danger: 'has-text-danger far fa-exclamation-triangle'
}

export const priceColourClass = ({ pricePosition }) => {
  if (!pricePosition) return ''
  else if (pricePosition === 'PricedWell') return 'is-success'
  else if (pricePosition === 'ConsiderReview') return 'is-warning'
  else return 'is-danger'
}

const pricePositionClass = ({ pricePosition }) => {
  const keyMap = {
    PricedWell: icons.success,
    ConsiderReview: icons.warning,
    OverPriced: icons.danger,
    UnderPriced: icons.danger
  }
  return keyMap[pricePosition]
}

const daysInStockClass = ({ daysInStock }) => {
  if (daysInStock >= 80) return icons.danger
  if (daysInStock >= 70) return icons.warning
  return ''
}

const lastPriceChangeClass = ({ daysSinceLastPriceChange }) => {
  return daysSinceLastPriceChange >= 30 ? icons.danger : ''
}

const isAlert = ({ price, daysInStock, lastPriceChange }) => {
  const alerts = [price, daysInStock, lastPriceChange]
  return alerts.every(value => !!value)
}

export const getIcons = listing => {
  const price = priceColourClass(listing)
  const pricePosition = pricePositionClass(listing)
  const daysInStock = daysInStockClass(listing)
  const lastPriceChange = lastPriceChangeClass(listing)
  const alert = isAlert({ price, daysInStock, lastPriceChange })
  return { price, pricePosition, daysInStock, lastPriceChange, alert }
}

export const hasColumn = (properties, column) => {
  return properties.find(prop => prop.value === column)?.display
}
