import Vue from 'vue'
import { node } from '@/plugins/axios'
import qs from 'qs'
import { upperFirst } from 'lodash'
const notify = (params) => Vue.prototype.$notify(params)

export const getListings = async (params) => {
  try {
    const { data } = await node.get('/stockview/listings', {
      params: {
        ...params.filters,
        limit: params.pageSize,
        sellerId: [params.sellerId],
        sortBy: params.sortBy,
        sortOrder: params.sortOrder,
        valuationInsightType: params.valuationInsightType ? upperFirst(params.valuationInsightType) : undefined,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets', skipNulls: true })
    })
    return data
  } catch (err) {
    notify('Currently unable to retrieve your listings')
    throw err
  }
}

export const getFilterOptions = async (params) => {
  try {
    const { filter, sellerId, filters, valuationInsightType } = params

    const { data } = await node.get(`/stockview/listings/filters/${filter}`, {
      params: {
        ...filters,
        page: undefined,
        sellerId: [sellerId],
        valuationInsightType
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets', skipNulls: true })
    })
    return data
  } catch (err) {
    notify('Currently unable to retrieve your filters')
    throw err
  }
}
